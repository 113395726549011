import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const UserTable = (props) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Edit / Delete</TableCell>
            <TableCell>First</TableCell>
            <TableCell>Last</TableCell>
            <TableCell>Mobile</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Coach</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props?.users.length > 0 ? (
            props?.users.map((user, index) => (
              <TableRow key={index}>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      props.editRow(user);
                    }}
                    type="submit">
                    <EditIcon />
                  </IconButton>
                  {props?.currentUserId !== user?.id ? (
                    <IconButton
                      color="primary"
                      onClick={() => props.deleteUser(user?.id)}
                      type="submit">
                      <DeleteOutlineIcon />
                    </IconButton>
                  ) : (
                    ''
                  )}
                </TableCell>

                <TableCell>
                  <input type="hidden" value={user?.id} />
                  {user?.first_name}
                </TableCell>
                <TableCell>{user?.last_name}</TableCell>
                <TableCell>{user?.mobile_phone}</TableCell>
                <TableCell>{user?.email}</TableCell>
                <TableCell>{user?.role === 3 ? 'User' : 'Admin'}</TableCell>
                <TableCell>
                  <div>{user?.coach ? 'Yes' : 'No'}</div>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3}>Click above to add users!</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserTable;
