import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import { forwardRef } from 'react';
import { UserContext } from '../../../../UserContext';
import jwt_decode from 'jwt-decode';
import useFetch from '../../../../common/useFetch';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const UsersTable = () => {
  const { user, setUser } = useContext(UserContext);
  const { leagueId } = jwt_decode(user.accessToken);

  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
      Authorization: `Bearer ${user.accessToken}`
    },
    credentials: 'include',
    body: JSON.stringify({
      leagueId: leagueId
    })
  };

  const [state, setState] = useState([]);
  const [coach, setCoach] = useState('');

  const { data } = useFetch('/user/coaches', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
      Authorization: `Bearer ${user.accessToken}`
    },
    credentials: 'include',
    body: JSON.stringify({
      leagueId: leagueId
    })
  });

  useEffect(() => {
    if (data) {
      setState(data.user);
    }
  }, [data]);

  const API_URL =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:4000'
      : 'https://api.teamupdate.me';

  return (
    <div style={{ maxWidth: '100%' }}>
      <MaterialTable
        columns={[
          {
            title: 'Team Name',
            field: 'team_name',
            validate: (rowData) =>
              rowData.team_name
                ? rowData.team_name.length < 2
                  ? {
                      isValid: false,
                      helperText: 'Team Name must be at least 2 characters'
                    }
                  : true
                : true
          },
          {
            title: 'Opt-In Code',
            field: 'opt_in_code',
            validate: (rowData) =>
              rowData.opt_in_code
                ? rowData.opt_in_code.length < 2
                  ? {
                      isValid: false,
                      helperText: 'Opt-In Code must be at least 2 characters'
                    }
                  : rowData.opt_in_code.indexOf(' ') > 0
                  ? {
                      isValid: false,
                      helperText: 'Opt-In Code must not contain spaces'
                    }
                  : true
                : true
          },
          {
            title: 'Coach Name',
            field: 'fk_coach_id',
            render: (rowData) => (
              <label>
                {rowData
                  ? rowData.User.first_name + ' ' + rowData.User.last_name
                  : coach.first_name + ' ' + coach.last_name}
              </label>
            ),
            editComponent: (props) => (
              <TextField
                helperText="Select Coach"
                label="Select"
                onChange={(e) =>
                  props.onChange(e.target.value, setCoach(e.target.value))
                }
                select
                value={
                  coach !== ''
                    ? coach
                    : props.rowData.hasOwnProperty.call('User')
                    ? props.rowData.User.id
                    : props.rowData.hasOwnProperty.call('fk_coach_id')
                    ? props.rowData.fk_coach_id
                    : ''
                }>
                {state.map((coach) => (
                  <MenuItem key={coach.id} value={coach.id}>
                    {coach.first_name + ' ' + coach.last_name}
                  </MenuItem>
                ))}
              </TextField>
            )
          }
        ]}
        data={() =>
          new Promise((resolve, reject) => {
            let url = '/team/list-coach';
            fetch(API_URL + url, config)
              .then((response) => response.json())
              .then((result) => {
                if (result.message === 'Access token expired') {
                  fetch('http://localhost:4000/auth/refresh', {
                    method: 'POST',
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                      Cache: 'no-cache'
                    },
                    credentials: 'include'
                  })
                    .then((tokenResponse) => tokenResponse.json())
                    .then((newToken) => {
                      if (newToken.isAuthenticated === true) {
                        setUser({
                          username: newToken.username,
                          isAuthenticated: newToken.isAuthenticated,
                          accessToken: newToken.accessToken,
                          tokenExpiresOn: newToken.tokenExpiresOn
                        });

                        config.headers.Authorization =
                          'Bearer ' + newToken.accessToken;

                        fetch(API_URL + url, config)
                          .then((newResponse) => newResponse.json())
                          .then((newResult) => {
                            resolve({
                              data: newResult.teams,
                              page: 0,
                              totalCount: newResult.teams.length
                            });
                          });
                      }
                    });
                } else {
                  resolve({
                    data: result.teams,
                    page: 0,
                    totalCount: result.teams.length
                  });
                }
              });
          })
        }
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              let url = '/team';
              fetch(API_URL + url, {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  Cache: 'no-cache',
                  Authorization: `Bearer ${user.accessToken}`
                },
                credentials: 'include',
                body: JSON.stringify({
                  leagueId: leagueId,
                  newTeam: newData
                })
              })
                .then((response) => response.json())
                .then((result) => {
                  if (result.message === 'Access token expired') {
                    fetch(API_URL + '/auth/refresh', {
                      method: 'POST',
                      headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Cache: 'no-cache'
                      },
                      credentials: 'include'
                    })
                      .then((tokenResponse) => tokenResponse.json())
                      .then((newToken) => {
                        if (newToken.isAuthenticated === true) {
                          setUser({
                            username: newToken.username,
                            isAuthenticated: newToken.isAuthenticated,
                            accessToken: newToken.accessToken,
                            tokenExpiresOn: newToken.tokenExpiresOn
                          });

                          fetch(API_URL + url, {
                            method: 'POST',
                            headers: {
                              Accept: 'application/json',
                              'Content-Type': 'application/json',
                              Cache: 'no-cache',
                              Authorization: `Bearer ${newToken.accessToken}`
                            },
                            credentials: 'include',
                            body: JSON.stringify({
                              leagueId: leagueId,
                              newTeam: newData
                            })
                          })
                            .then((newResponse) => newResponse.json())
                            .then((newResult) => {
                              resolve({
                                data: newResult.teams,
                                page: 0,
                                totalCount: newResult.teams.length
                              });
                            });
                        }
                      });
                  } else {
                    resolve({
                      data: result.teams,
                      page: 0,
                      totalCount: result.teams.length
                    });
                  }
                });
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              let url = '/team';
              fetch(API_URL + url, {
                method: 'PUT',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  Cache: 'no-cache',
                  Authorization: `Bearer ${user.accessToken}`
                },
                credentials: 'include',
                body: JSON.stringify({
                  oldData: oldData,
                  newData: newData
                })
              })
                .then((response) => response.json())
                .then((result) => {
                  if (result.message === 'Access token expired') {
                    fetch(API_URL + '/auth/refresh', {
                      method: 'POST',
                      headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Cache: 'no-cache'
                      },
                      credentials: 'include'
                    })
                      .then((tokenResponse) => tokenResponse.json())
                      .then((newToken) => {
                        if (newToken.isAuthenticated === true) {
                          setUser({
                            username: newToken.username,
                            isAuthenticated: newToken.isAuthenticated,
                            accessToken: newToken.accessToken,
                            tokenExpiresOn: newToken.tokenExpiresOn
                          });

                          fetch(API_URL + url, {
                            method: 'PUT',
                            headers: {
                              Accept: 'application/json',
                              'Content-Type': 'application/json',
                              Cache: 'no-cache',
                              Authorization: `Bearer ${newToken.accessToken}`
                            },
                            credentials: 'include',
                            body: JSON.stringify({
                              oldData: oldData,
                              newData: newData
                            })
                          })
                            .then((newResponse) => newResponse.json())
                            .then((newResult) => {
                              resolve({
                                data: newResult.teams,
                                page: 0,
                                totalCount: newResult.teams.length
                              });
                            });
                        }
                      });
                  } else {
                    resolve({
                      data: result.teams,
                      page: 0,
                      totalCount: result.teams.length
                    });
                  }
                });
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              let url = '/team';
              fetch(API_URL + url, {
                method: 'DELETE',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  Cache: 'no-cache',
                  Authorization: `Bearer ${user.accessToken}`
                },
                credentials: 'include',
                body: JSON.stringify({
                  teamId: oldData
                })
              })
                .then((response) => response.json())
                .then((result) => {
                  if (result.message === 'Access token expired') {
                    fetch(API_URL + '/auth/refresh', {
                      method: 'POST',
                      headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Cache: 'no-cache'
                      },
                      credentials: 'include'
                    })
                      .then((tokenResponse) => tokenResponse.json())
                      .then((newToken) => {
                        if (newToken.isAuthenticated === true) {
                          setUser({
                            username: newToken.username,
                            isAuthenticated: newToken.isAuthenticated,
                            accessToken: newToken.accessToken,
                            tokenExpiresOn: newToken.tokenExpiresOn
                          });

                          fetch(API_URL + url, {
                            method: 'DELETE',
                            headers: {
                              Accept: 'application/json',
                              'Content-Type': 'application/json',
                              Cache: 'no-cache',
                              Authorization: `Bearer ${newToken.accessToken}`
                            },
                            credentials: 'include',
                            body: JSON.stringify({
                              teamId: oldData
                            })
                          })
                            .then((newResponse) => newResponse.json())
                            .then((newResult) => {
                              resolve({
                                data: newResult.teams,
                                page: 0,
                                totalCount: newResult.teams.length
                              });
                            });
                        }
                      });
                  } else {
                    resolve({
                      data: result.teams,
                      page: 0,
                      totalCount: result.teams.length
                    });
                  }
                });
            })
        }}
        icons={tableIcons}
        options={{
          search: false,
          showTitle: true
        }}
        title="Manage Teams"
      />
    </div>
  );
};

export default UsersTable;
