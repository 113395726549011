import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UserContext } from '../../UserContext';

export const AuthenticatedRoute = (props) => {
  let {
    layout: Layout,
    component: Component,
    statusErrorComponent: StatusComponent,
    ...rest
  } = props;
  const { user } = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        StatusComponent !== null && user.isAuthenticated ? (
          <Layout>
            <StatusComponent {...matchProps} />
          </Layout>
        ) : user.isAuthenticated ? (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        ) : (
          <Redirect
            to={`/sign-in?redirect=${props.location.pathname}${props.location.search}`}
          />
        )
      }
    />
  );
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default AuthenticatedRoute;
