import React, { useState, useEffect, useContext } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import {
  Button,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Divider,
  CardActions,
  Grid,
  CircularProgress,
  Typography
} from '@material-ui/core';
import validate from 'validate.js';
import { useFetch } from '../../../../common/useFetch';
import { fetchData } from '../../../../common/fetchData';
import { UserContext } from '../../../../UserContext';
import jwt_decode from 'jwt-decode';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core/styles';
import './CardSectionStyles.css';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#3F51B5',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '15px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start'
  },
  div: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start',
    justifyContent: 'space-between'
  },
  signUpButton: {
    margin: theme.spacing(1, 0)
  },
  buttonWrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start',
    justifyContent: 'space-between'
  },
  buttonProgress: {
    top: '55%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    position: 'absolute'
  },
  cardWarningText: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start',
    justifyContent: 'center',
    marginLeft: '15px',
    marginTop: '10px'
  },
  cardWarningIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start',
    justifyContent: 'center',
    color: 'red',
    marginLeft: '5px',
    marginRight: '5px',
    marginTop: '-3px'
  },
  cardOkIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start',
    justifyContent: 'center',
    color: 'green',
    marginLeft: '5px',
    marginRight: '5px',
    marginTop: '-3px'
  },
  cardOnFile: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start',
    justifyContent: 'center',
    marginLeft: '15px',
    marginTop: '15px',
    marginRight: '15px',
    marginBottom: '15px',
    paddingTop: '5px',
    height: '40px'
  }
}));

const schema = {
  billingFirstName: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 20
    },
    format: {
      pattern: '[a-z ]+',
      flags: 'i',
      message: 'can only contain a-z'
    }
  },
  billingLastName: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 20
    },
    format: {
      pattern: '[a-z ]+',
      flags: 'i',
      message: 'can only contain a-z'
    }
  },
  billingEmail: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  billingPhone: {
    presence: { allowEmpty: false, message: 'is required 123-456-7890' },
    length: {
      maximum: 12
    }
  }
};

const PaymentDetails = () => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      billingFirstName: '',
      billingLastName: '',
      billingEmail: '',
      billingPhone: '',
      last4: '',
      cardBrand: '',
      custId: ''
    },
    touched: {},
    errors: {},
    isLoading: false,
    stripeFormComplete: false,
    accountStatus: '',
    openInvoices: null
  });

  const { user, setUser } = useContext(UserContext);
  const { customerId } = jwt_decode(user.accessToken);
  let customerStatus = user.customerStatus;

  // udpate customerStatus in state
  useEffect(() => {
    setFormState((formState) => ({
      ...formState,
      accountStatus: customerStatus
    }));
  }, [customerStatus]);

  const [openSnack, setOpenSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
    setOpenErrorSnack(false);
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handlePhoneChange = (event) => {
    event.persist();

    var x = event.target.value
      .replace(/\D/g, '')
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    x = event.target.value = !x[2]
      ? x[1]
      : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : x
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleCardChange = (event) => {
    if (event.complete) {
      console.log('stripe form complete');
      setFormState((formState) => ({
        ...formState,
        stripeFormComplete: true
      }));
    } else if (event.error) {
      setFormState((formState) => ({
        ...formState,
        stripeFormComplete: false
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setFormState((formState) => ({
      ...formState,
      isLoading: true
    }));

    if (!stripe || !elements) {
      // Disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        email: formState.values.billingEmail,
        name:
          formState.values.billingFirstName +
          ' ' +
          formState.values.billingLastName,
        phone: formState.values.billingPhone
      }
    });

    if (result.error) {
      // Show error in payment form
      setOpenErrorSnack(true);
      console.log(result.error.message);
    } else {
      try {
        const { data, newUser } = await fetchData(
          '/pmt/retry-invoices',
          user.accessToken,
          'POST',
          {
            billingFirstName: formState.values.billingFirstName,
            billingLastName: formState.values.billingLastName,
            billingPhone: formState.values.billingPhone,
            billingEmail: formState.values.billingEmail,
            custId: formState.values.custId,
            paymentMethodId: result.paymentMethod.id,
            customerId: customerId
          }
        );

        if (newUser) {
          setUser(newUser);
        }

        // The customer has been created
        let customer = null;
        if (data) {
          customer = data;
        }
        const { clientSecret, status } = customer;

        // verify successful payment
        if (status === 'requires_action') {
          stripe.confirmCardPayment(clientSecret).then(function (result) {
            if (result.error) {
              console.log(
                'There was an error processing your card.  Please try again.'
              );
              console.log(result.error);
              setOpenErrorSnack(true);
            } else {
              console.log('Your account was setup successfully!');
              setOpenSnack(true);
            }
          });
        }

        if (data.success === false) {
          setOpenErrorSnack(true);
        }

        setFormState((formState) => ({
          ...formState,
          accountStatus:
            typeof data.customer !== 'undefined'
              ? data.customer.customer_status
              : formState.accountStatus,
          isLoading: false
        }));
        console.log(formState.accountStatus);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  // customer

  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
      Authorization: `Bearer ${user.accessToken}`
    },
    credentials: 'include',
    body: JSON.stringify({
      customerId: customerId
    })
  };

  const { data } = useFetch('/customer/current', config);

  useEffect(() => {
    if (data) {
      setFormState((formState) => ({
        ...formState,
        values: {
          last4: data.customer.stripe_last4,
          cardBrand: data.customer.stripe_card_brand,
          custId: data.customer.stripe_customer_id,
          billingFirstName: data.customer.stripe_billing_first_name,
          billingLastName: data.customer.stripe_billing_last_name,
          billingEmail: data.customer.stripe_billing_email,
          billingPhone: data.customer.stripe_billing_phone
        }
      }));
    }
    // eslint-disable-next-line
  }, [data]);

  // invoices
  useEffect(() => {
    console.log(JSON.stringify(formState.openInvoices));
    const retrieveData = async () => {
      try {
        const { data, newUser } = await fetchData(
          '/pmt/retrieve-customer-invoices',
          user.accessToken,
          'POST',
          {
            custId: formState.values.stripe_customer_id
          }
        );

        console.log(JSON.stringify(data, null, 2));

        if (newUser) {
          setUser(newUser);
        }

        if (data) {
          setFormState((formState) => ({
            ...formState,
            openInvoices: data
          }));

          console.log(JSON.stringify(formState.openInvoices, null, 2));
        }
      } catch (error) {
        console.log(JSON.stringify(error, null, 2));
      }
    };

    retrieveData();

    // eslint-disable-next-line
  }, []);

  console.log(formState.openInvoices);

  return (
    <div>
      <Snackbar autoHideDuration={6000} onClose={handleClose} open={openSnack}>
        <Alert onClose={handleClose} severity="success">
          Your card update was successful!
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={6000}
        onClose={handleClose}
        open={openErrorSnack}>
        <Alert onClose={handleClose} severity="error">
          Card update failed. Please try again.
        </Alert>
      </Snackbar>

      <Card className={classes.root}>
        <CardHeader
          subheader="Use the form below to update your billing information"
          title="Payment Details"
        />
        <Divider />

        {formState.accountStatus === 'updateCard' ? (
          <div className={classes.cardWarningText}>
            <Typography variant="h5" color="error">
              Please update payment method
            </Typography>
          </div>
        ) : (
          ''
        )}

        <div className={classes.cardOnFile}>
          {formState.accountStatus === 'updateCard' ? (
            <ErrorOutlineIcon className={classes.cardWarningIcon} />
          ) : (
            <CheckIcon className={classes.cardOkIcon} />
          )}
          <Typography variant="h5" color="textSecondary">
            Card on file:
          </Typography>
          &nbsp;
          <Typography color="textSecondary" gutterBottom>
            {formState.values.cardBrand.charAt(0).toUpperCase() +
              formState.values.cardBrand.slice(1)}
            {' ending in .....' + formState.values.last4}
          </Typography>
        </div>
        <div>
          {formState.openInvoices !== null
            ? formState.openInvoices.data[0].amount_due
            : // formState.openInvoices.data.map((item, i) => (
              //   <li key={i}>
              //     {JSON.stringify(formState.openInvoices.data[i].amount_due) +
              //       ' ' +
              //       formState.openInvoices.data[i].due_date}
              //   </li>
              // ))
              ''}
        </div>
        <Divider />
        <CardContent className={classes.content}>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError('billingFirstName')}
                fullWidth
                helperText={
                  hasError('billingFirstName')
                    ? formState.errors.billingFirstName[0]
                    : null
                }
                label="Billing first name"
                margin="dense"
                name="billingFirstName"
                onChange={handleChange}
                required
                value={formState.values.billingFirstName || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError('billingLastName')}
                fullWidth
                helperText={
                  hasError('billingLastName')
                    ? formState.errors.billingLastName[0]
                    : null
                }
                label="Billing last name"
                margin="dense"
                name="billingLastName"
                onChange={handleChange}
                required
                value={formState.values.billingLastName || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError('billingEmail')}
                fullWidth
                helperText={
                  hasError('billingEmail')
                    ? formState.errors.billingEmail[0]
                    : null
                }
                label="Billing email Address"
                margin="dense"
                name="billingEmail"
                onChange={handleChange}
                required
                value={formState.values.billingEmail || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError('billingPhone')}
                fullWidth
                helperText={
                  hasError('billingPhone')
                    ? formState.errors.billingPhone[0]
                    : null
                }
                label="Billing phone Number"
                margin="dense"
                name="billingPhone"
                onChange={handlePhoneChange}
                inputProps={{ maxLength: 12 }}
                required
                type="tel"
                value={formState.values.billingPhone || ''}
                variant="outlined"
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <div
                style={{
                  marginTop: '12px',
                  border: '1px solid gray',
                  borderRadius: '4px'
                }}>
                <CardElement
                  onChange={handleCardChange}
                  options={CARD_ELEMENT_OPTIONS}
                />
              </div>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Grid item md={12} xs={12}>
            <div className={classes.buttonWrapper}>
              <Button
                className={classes.button}
                color="primary"
                disabled={
                  !formState.isValid ||
                  formState.isLoading ||
                  !formState.stripeFormComplete
                }
                onClick={handleSubmit}
                variant="contained">
                Update Payment Details
              </Button>
              {formState.isLoading && (
                <CircularProgress
                  color="secondary"
                  size={30}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Grid>
        </CardActions>
      </Card>
    </div>
  );
};

export default PaymentDetails;
