import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Link as RouterLink } from 'react-router-dom';
import {
  Button,
  TextField,
  Link,
  FormHelperText,
  Checkbox,
  Typography,
  Card,
  CardContent,
  MenuItem,
  Grid,
  CircularProgress
} from '@material-ui/core';
import CardInput from './CardInput';
import validate from 'validate.js';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
    margin: '5vh auto'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start'
  },
  div: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start',
    justifyContent: 'space-between'
  },
  button: {
    margin: '2em auto 1em'
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  },
  title: {
    marginTop: theme.spacing(3)
  },
  plan: {
    marginTop: theme.spacing(3),
    color: 'white',
    padding: '12px 0px 5px 0px',
    marginBottom: '15px',
    backgroundColor: theme.palette.primary.main
  },
  buttonWrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start',
    justifyContent: 'space-between'
  },
  buttonProgress: {
    top: '55%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    position: 'absolute'
  }
}));

const schema = {
  leagueName: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 100
    },
    format: {
      pattern: '[a-z0-9 ]+',
      flags: 'i',
      message: 'can only contain a-z and 0-9'
    }
  },
  firstName: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 20
    },
    format: {
      pattern: '[a-z ]+',
      flags: 'i',
      message: 'can only contain a-z'
    }
  },
  lastName: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 20
    },
    format: {
      pattern: '[a-z ]+',
      flags: 'i',
      message: 'can only contain a-z'
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  phone: {
    presence: { allowEmpty: false, message: 'is required 123-456-7890' },
    length: {
      maximum: 12
    }
  },
  street: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 50
    },
    format: {
      pattern: '[a-z0-9 ]+',
      flags: 'i',
      message: 'can only contain a-z and 0-9'
    }
  },
  line2: {
    presence: { allowEmpty: true, message: 'maximum length is 50 characters' },
    length: {
      maximum: 20
    }
  },
  city: {
    presence: true,
    length: {
      minimum: 2,
      maximum: 25
    },
    format: {
      pattern: '[a-z ]+',
      flags: 'i',
      message: 'can only contain a-z'
    }
  },
  st: {
    presence: true,
    length: {
      minimum: 2,
      maximum: 2
    },
    format: {
      pattern: '[a-z]+',
      flags: 'i',
      message: 'can only contain a-z'
    }
  },
  zip: {
    presence: true,
    length: {
      minimum: 5,
      maximum: 5
    },
    format: {
      pattern: '[0-9]+',
      flags: 'i',
      message: 'can only contain 0-9'
    }
  },
  policy: {
    presence: {
      message:
        '^You must check the box to verify you have read the terms and conditions'
    },
    inclusion: {
      within: [true],
      message:
        '^You must check the box to verify you have read the terms and conditions'
    }
  }
};

const CheckoutForm = () => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      leagueName: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      street: '',
      line2: '',
      city: '',
      st: '',
      zip: '',
      policy: ''
    },
    touched: {},
    errors: {},
    isLoading: false
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handlePhoneChange = (event) => {
    event.persist();

    var x = event.target.value
      .replace(/\D/g, '')
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    x = event.target.value = !x[2]
      ? x[1]
      : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : x
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setFormState((formState) => ({
      ...formState,
      isLoading: true
    }));

    if (!stripe || !elements) {
      // Disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        email: formState.values.email,
        name: formState.values.firstName + ' ' + formState.values.lastName,
        phone: formState.values.phone,
        address: {
          line1: formState.values.street,
          line2: formState.values.street,
          city: formState.values.city,
          state: formState.values.state,
          postal_code: formState.values.zip
        }
      }
    });

    if (result.error) {
      // Show error in payment form
      console.log(result.error.message);
    } else {
      try {
        const res = await fetch(
          'http://localhost:4000/signup/create-customer',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              leagueName: formState.values.leagueName,
              firstName: formState.values.firstName,
              lastName: formState.values.lastName,
              phone: formState.values.phone,
              street: formState.values.street,
              line2: formState.values.line2,
              city: formState.values.city,
              st: formState.values.st,
              zip: formState.values.zip,
              policy: formState.values.policy,
              email: formState.values.email,
              payment_method: result.paymentMethod.id
            })
          }
        );

        // The customer has been created
        const customer = await res.json();

        const { clientSecret, status } = customer;

        // verify successful payment
        if (status === 'requires_action') {
          stripe.confirmCardPayment(clientSecret).then(function (result) {
            if (result.error) {
              console.log(
                'There was an error processing your card.  Please try again.'
              );
              console.log(result.error);
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc)
            } else {
              console.log('Your account was setup successfully!');
              // Show a success message to your customer
            }
          });
        }
        //setFormState((formState.isLoading = false));
        setFormState((formState) => ({
          ...formState,
          isLoading: false
        }));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Typography className={classes.title} variant="h2">
          Create Your Account
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          Enter your details below to get started immediately!
        </Typography>
        <Grid container spacing={1}>
          <Grid item md={12} xs={12}>
            <div
              style={{
                marginTop: '12px',
                paddingTop: '7px',
                border: '1px solid #cccccc',
                borderRadius: '4px',
                textAlign: 'center'
              }}>
              <div className={classes.plan}>
                <Typography variant="h2" color="inherit" gutterBottom>
                  <b>Monthly Subscription</b>
                </Typography>
              </div>

              <Typography variant="h1" color="primary" gutterBottom>
                <b>$10.00</b>
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                Per month flat fee
              </Typography>
              <Typography variant="h1" color="primary" gutterBottom>
                <b>+ $0.03</b>
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                Per message sent
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                Billed monthly
              </Typography>
            </div>
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              error={hasError('leagueName')}
              fullWidth
              helperText={
                hasError('leagueName') ? formState.errors.leagueName[0] : null
              }
              label="League name"
              margin="normal"
              name="leagueName"
              onChange={handleChange}
              required
              value={formState.values.leagueName || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <TextField
              error={hasError('firstName')}
              fullWidth
              helperText={
                hasError('firstName') ? formState.errors.firstName[0] : null
              }
              label="First name"
              margin="normal"
              name="firstName"
              onChange={handleChange}
              required
              value={formState.values.firstName || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <TextField
              error={hasError('lastName')}
              fullWidth
              helperText={
                hasError('lastName') ? formState.errors.lastName[0] : null
              }
              label="Last name"
              margin="normal"
              name="lastName"
              onChange={handleChange}
              required
              value={formState.values.lastName || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              error={hasError('email')}
              fullWidth
              helperText={hasError('email') ? formState.errors.email[0] : null}
              label="Email Address"
              margin="normal"
              name="email"
              onChange={handleChange}
              required
              value={formState.values.email || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              error={hasError('phone')}
              fullWidth
              helperText={hasError('phone') ? formState.errors.phone[0] : null}
              label="Phone Number"
              margin="normal"
              name="phone"
              onChange={handlePhoneChange}
              inputProps={{ maxLength: 12 }}
              required
              type="tel"
              value={formState.values.phone || ''}
              variant="outlined"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              error={hasError('street')}
              fullWidth
              helperText={
                hasError('street') ? formState.errors.street[0] : null
              }
              label="Street Address"
              margin="normal"
              name="street"
              inputProps={{ maxLength: 50 }}
              onChange={handleChange}
              required
              value={formState.values.street || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              error={hasError('line2')}
              fullWidth
              helperText={hasError('line2') ? formState.errors.line2[0] : null}
              label="Suite #"
              margin="normal"
              name="line2"
              inputProps={{ maxLength: 20 }}
              onChange={handleChange}
              required
              value={formState.values.line2 || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              error={hasError('city')}
              fullWidth
              helperText={hasError('city') ? formState.errors.city[0] : null}
              label="City"
              margin="normal"
              name="city"
              inputProps={{ maxLength: 20 }}
              onChange={handleChange}
              required
              value={formState.values.city || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <TextField
              fullWidth
              label="State"
              margin="normal"
              name="st"
              onChange={handleChange}
              required
              select
              type="text"
              value={formState.values.st || ''}
              variant="outlined">
              <MenuItem value="AL">Alabama</MenuItem>
              <MenuItem value="AK">Alaska</MenuItem>
              <MenuItem value="AZ">Arizona</MenuItem>
              <MenuItem value="AR">Arkansas</MenuItem>
              <MenuItem value="CA">California</MenuItem>
              <MenuItem value="CO">Colorado</MenuItem>
              <MenuItem value="CT">Connecticut</MenuItem>
              <MenuItem value="DE">Delaware</MenuItem>
              <MenuItem value="DC">District Of Columbia</MenuItem>
              <MenuItem value="FL">Florida</MenuItem>
              <MenuItem value="GA">Georgia</MenuItem>
              <MenuItem value="HI">Hawaii</MenuItem>
              <MenuItem value="ID">Idaho</MenuItem>
              <MenuItem value="IL">Illinois</MenuItem>
              <MenuItem value="IN">Indiana</MenuItem>
              <MenuItem value="IA">Iowa</MenuItem>
              <MenuItem value="KS">Kansas</MenuItem>
              <MenuItem value="KY">Kentucky</MenuItem>
              <MenuItem value="LA">Louisiana</MenuItem>
              <MenuItem value="ME">Maine</MenuItem>
              <MenuItem value="MD">Maryland</MenuItem>
              <MenuItem value="MA">Massachusetts</MenuItem>
              <MenuItem value="MI">Michigan</MenuItem>
              <MenuItem value="MN">Minnesota</MenuItem>
              <MenuItem value="MS">Mississippi</MenuItem>
              <MenuItem value="MO">Missouri</MenuItem>
              <MenuItem value="MT">Montana</MenuItem>
              <MenuItem value="NE">Nebraska</MenuItem>
              <MenuItem value="NV">Nevada</MenuItem>
              <MenuItem value="NH">New Hampshire</MenuItem>
              <MenuItem value="NJ">New Jersey</MenuItem>
              <MenuItem value="NM">New Mexico</MenuItem>
              <MenuItem value="NY">New York</MenuItem>
              <MenuItem value="NC">North Carolina</MenuItem>
              <MenuItem value="ND">North Dakota</MenuItem>
              <MenuItem value="OH">Ohio</MenuItem>
              <MenuItem value="OK">Oklahoma</MenuItem>
              <MenuItem value="OR">Oregon</MenuItem>
              <MenuItem value="PA">Pennsylvania</MenuItem>
              <MenuItem value="RI">Rhode Island</MenuItem>
              <MenuItem value="SC">South Carolina</MenuItem>
              <MenuItem value="SD">South Dakota</MenuItem>
              <MenuItem value="TN">Tennessee</MenuItem>
              <MenuItem value="TX">Texas</MenuItem>
              <MenuItem value="UT">Utah</MenuItem>
              <MenuItem value="VT">Vermont</MenuItem>
              <MenuItem value="VA">Virginia</MenuItem>
              <MenuItem value="WA">Washington</MenuItem>
              <MenuItem value="WV">West Virginia</MenuItem>
              <MenuItem value="WI">Wisconsin</MenuItem>
              <MenuItem value="WY">Wyoming</MenuItem>
            </TextField>
          </Grid>
          <Grid item md={4} xs={6}>
            <TextField
              error={hasError('zip')}
              fullWidth
              helperText={hasError('zip') ? formState.errors.zip[0] : null}
              label="Zip Code"
              margin="normal"
              name="zip"
              onChange={handleChange}
              required
              inputProps={{ maxLength: 5 }}
              value={formState.values.zip || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <div
              style={{
                marginTop: '12px',
                border: '1px solid gray',
                borderRadius: '4px'
              }}>
              <CardInput />
            </div>
          </Grid>

          <Grid item md={12} xs={12}>
            <div className={classes.policy}>
              <Checkbox
                checked={formState.values.policy || false}
                className={classes.policyCheckbox}
                color="primary"
                name="policy"
                onChange={handleChange}
              />
              <Typography
                className={classes.policyText}
                color="textSecondary"
                variant="body1">
                I have read and agree to the{' '}
                <Link
                  color="primary"
                  component={RouterLink}
                  to="#"
                  underline="always"
                  variant="h6">
                  Terms and Conditions
                </Link>
              </Typography>
            </div>
            {hasError('policy') && (
              <FormHelperText error>
                {formState.errors.policy[0]}
              </FormHelperText>
            )}
          </Grid>

          <Grid item md={12} xs={12}>
            <div className={classes.buttonWrapper}>
              <Button
                className={classes.button}
                color="primary"
                disabled={!formState.isValid || formState.isLoading}
                onClick={handleSubmit}
                variant="contained">
                Signup!
              </Button>
              {formState.isLoading && (
                <CircularProgress
                  color="secondary"
                  size={30}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Grid>
        </Grid>
        <Typography color="textSecondary" variant="body1">
          Have an account?{' '}
          <Link component={RouterLink} to="/sign-in" variant="h6">
            Sign in
          </Link>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CheckoutForm;
