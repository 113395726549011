import React, { useState, useMemo, useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { UserContext } from './UserContext';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import { chartjs } from './helpers';
import theme from './theme';
//import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

const App = () => {
  const [user, setUser] = useState({
    username: '',
    isAuthenticated: false,
    customerStatus: '',
    accessToken: '',
    tokenExpiresOn: ''
  });
  const value = useMemo(() => ({ user, setUser }), [user, setUser]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const API_URL =
          process.env.NODE_ENV === 'development'
            ? 'http://localhost:4000'
            : 'https://api.teamupdate.me';
        const res = await fetch(API_URL + '/auth/refresh', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Cache: 'no-cache'
          },
          credentials: 'include'
        });

        const newAccessToken = await res.json();

        if (newAccessToken) {
          setUser({
            username: newAccessToken.username,
            isAuthenticated: newAccessToken.isAuthenticated,
            customerStatus: newAccessToken.customerStatus,
            accessToken: newAccessToken.accessToken,
            tokenExpiresOn: newAccessToken.tokenExpiresOn
          });
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const stripePromise = loadStripe(
    'pk_test_ct8w15h2aCnNTTLDMKag1cTJ00CINWXRNt'
  );

  return (
    <UserContext.Provider value={value}>
      <ThemeProvider theme={theme}>
        <Elements stripe={stripePromise}>
          <Router history={browserHistory}>
            <Routes />
          </Router>
        </Elements>
      </ThemeProvider>
    </UserContext.Provider>
  );
};

export default App;
