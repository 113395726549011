import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles({
  list: {
    width: 240
  }
});

const TeamDrawer = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false
  });

  const toggleDrawer = (side, open) => event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={classes.list}
      onKeyDown={toggleDrawer(side, false)}
      role="presentation"
    >
      <List>
        {props.chips.length > 0
          ? props.chips.map((chip, index) => (
            <ListItem key={index}>
              <Button
                id={index}
                onClick={() => props.addChip(chip)}
                value={chip.id}
              >
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary={chip.label} />
              </Button>
            </ListItem>
          ))
          : ''}
      </List>
    </div>
  );

  return (
    <div>
      <Button
        color="primary"
        disabled={props.chips.length === 0}
        endIcon={<AddBoxIcon />}
        onClick={toggleDrawer('right', true)}
        variant="contained"
      >
        Teams
      </Button>
      <SwipeableDrawer
        anchor="right"
        onClose={toggleDrawer('right', false)}
        onOpen={toggleDrawer('right', true)}
        open={state.right}
      >
        {sideList('right')}
      </SwipeableDrawer>
    </div>
  );
};

export default TeamDrawer;
