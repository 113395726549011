import React, { useContext } from 'react';
import { UserContext } from '../../../../UserContext';
import useFetch from '../../../../common/useFetch';
import jwt_decode from 'jwt-decode';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import SmsIcon from '@material-ui/icons/Sms';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  }
}));

const TotalMessages = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const { user } = useContext(UserContext);
  const { customerId } = jwt_decode(user.accessToken);

  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
      Authorization: `Bearer ${user.accessToken}`
    },
    credentials: 'include',
    body: JSON.stringify({
      customerId: customerId
    })
  };

  const { data } = useFetch('/msg/monthly-total', config);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="inherit"
              gutterBottom
              variant="body2"
            >
              TOTAL MESSAGES THIS MONTH
            </Typography>
            <Typography
              color="inherit"
              variant="h3"
            >
              {!data ? <CircularProgress /> : data}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <SmsIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TotalMessages.propTypes = {
  className: PropTypes.string
};

export default TotalMessages;
