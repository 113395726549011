import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import './CardSectionStyles.css';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#3F51B5',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '15px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

function CardInput() {
  return <CardElement options={CARD_ELEMENT_OPTIONS} />;
}

export default CardInput;
