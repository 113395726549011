export const fetchData = async (url, accessToken, method, body) => {
  try {
    const API_URL =
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:4000'
        : 'https://api.teamupdate.me';
    let options = {
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
        Authorization: `Bearer ${accessToken}`
      },
      credentials: 'include',
      body: JSON.stringify(body)
    };
    const res = await fetch(API_URL + url, options);
    const json = await res.json();

    // attempt to refresh access token
    if (json.message === 'Access token expired') {
      const resNewAccessToken = await fetch(API_URL + '/auth/refresh', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Cache: 'no-cache'
        },
        credentials: 'include'
      });

      const newAccessToken = await resNewAccessToken.json();

      if (newAccessToken.isAuthenticated === true) {
        const newUserContext = {
          username: newAccessToken.username,
          isAuthenticated: newAccessToken.isAuthenticated,
          customerStatus: newAccessToken.customerStatus,
          accessToken: newAccessToken.accessToken,
          tokenExpiresOn: newAccessToken.tokenExpiresOn
        };

        options.headers.Authorization = 'Bearer ' + newAccessToken.accessToken;

        const resRefreshed = await fetch(API_URL + url, options);
        const jsonRefreshed = await resRefreshed.json();

        return { data: jsonRefreshed, newUser: newUserContext };
      } else {
        return { data: 'No token found', newUser: null };
      }
    }
    return { data: json, newUser: null };
  } catch (error) {
    return { data: error, newUser: null };
  }
};

export default fetchData;
