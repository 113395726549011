import React, { useContext } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { UserContext } from './UserContext';
import { RouteWithLayout, AuthenticatedRoute } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  Send as SendView,
  ManageUsers as ManageUsersView,
  UserList as TeamListView,
  Account as AccountView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView
} from './views';

const Routes = () => {
  const { user } = useContext(UserContext);
  const customerStatus = user.customerStatus;
  let statusComponent = null;

  switch (customerStatus) {
    case 'active':
      statusComponent = null;
      break;
    case 'inactive':
      statusComponent = AccountView;
      break;
    case 'updateCard':
      statusComponent = AccountView;
      break;
    default:
      statusComponent = null;
  }

  return (
    <Switch>
      <Redirect exact from="/" to="/dashboard" />
      <AuthenticatedRoute
        component={DashboardView}
        statusErrorComponent={statusComponent}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <AuthenticatedRoute
        component={SendView}
        statusErrorComponent={statusComponent}
        exact
        layout={MainLayout}
        path="/send"
      />
      <AuthenticatedRoute
        component={TeamListView}
        statusErrorComponent={statusComponent}
        exact
        layout={MainLayout}
        path="/teams"
      />
      <AuthenticatedRoute
        component={ManageUsersView}
        statusErrorComponent={statusComponent}
        exact
        layout={MainLayout}
        path="/users"
      />
      <AuthenticatedRoute
        component={AccountView}
        statusErrorComponent={statusComponent}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={SignUpView}
        statusErrorComponent={statusComponent}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        statusErrorComponent={statusComponent}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={NotFoundView}
        statusErrorComponent={statusComponent}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
