import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../../../UserContext';
import jwt_decode from 'jwt-decode';
import { useFetch } from '../../../../common/useFetch';
import { fetchData } from '../../../../common/fetchData';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SendIcon from '@material-ui/icons/Send';
import ChipInput from 'material-ui-chip-input';
import TeamDrawer from '../TeamDrawer';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: { display: 'flex' },
  cardStyle: {
    display: 'block',
    width: '100%',
    padding: '0 20 0 20',
    transitionDuration: '0.3s'
  },
  cardActions: {
    justifyContent: 'space-between',
    padding: 15
  },
  messageInput: {
    marginTop: '40px'
  },
  list: {
    width: 250
  },
  iconSize: {
    fontSize: '3rem',
    marginRight: '10px'
  }
}));

function Alert(props) {
  return <MuiAlert
    elevation={6}
    variant="filled"
    {...props}
  />;
}

const SendForm = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const maxChars = 306;

  const [openSnack, setOpenSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messageValue, setMessageValue] = useState('');
  const [charCount, setCharCount] = useState(maxChars);
  const [chipData, setChipData] = useState([]);
  const [rightDrawerChips, setRightDrawerChips] = useState([]);

  const handleMessageChange = ({ target }) => {
    setCharCount(() => maxChars - target.value.length);
    setMessageValue(target.value);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
    setOpenErrorSnack(false);
  };

  const { user, setUser } = useContext(UserContext);
  const { leagueId } = jwt_decode(user.accessToken);
  const { customerId } = jwt_decode(user.accessToken);

  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
      Authorization: `Bearer ${user.accessToken}`
    },
    credentials: 'include',
    body: JSON.stringify({
      leagueId: leagueId
    })
  };

  const { data } = useFetch('/team/list', config);

  useEffect(() => {
    if (data) {
      const initialChips = data.teams.map(team => {
        return { label: team.team_name, id: team.id };
      });
      setChipData(initialChips);
    }
  }, [data]);

  let chipIds = [];
  const parseChipData = item => {
    chipIds.push(item.id);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    chipData.forEach(parseChipData);

    setIsLoading(true);

    try {
      const { newUser } = await fetchData(
        '/msg/send',
        user.accessToken,
        'POST',
        {
          sendTo: chipIds,
          messageBody: messageValue,
          customerId: customerId
        }
      );

      setCharCount(maxChars);
      setOpenSnack(true);
      setIsLoading(false);

      if (newUser) {
        setUser(newUser);
      }
    } catch (e) {
      setIsLoading(false);
      setOpenErrorSnack(true);
      return e;
    }

    setMessageValue('');
  };

  const handleDeleteChip = chip => {
    setChipData(chipData.filter(c => c !== chip));
    setRightDrawerChips([...rightDrawerChips, chip]);
  };

  const addChip = chip => {
    setRightDrawerChips(rightDrawerChips.filter(c => c !== chip));
    setChipData([...chipData, chip]);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleClose}
        open={openSnack}
      >
        <Alert
          onClose={handleClose}
          severity="success"
        >
          Message sent successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleClose}
        open={openErrorSnack}
      >
        <Alert
          onClose={handleClose}
          severity="error"
        >
          Message failed. Please try again.
        </Alert>
      </Snackbar>

      <Card
        {...rest}
        className={clsx(classes.cardStyle, className)}
      >
        <form
          autoComplete="off"
          noValidate
        >
          <CardHeader
            subheader="Select message recipients, and enter your message below"
            title="Send League Update"
          />

          <Divider />
          <CardContent>
            <Grid
              alignItems="center"
              container
              spacing={0}
            >
              <Grid
                item
                md={12}
                xs={12}
              >
                <ChipInput
                  allowDuplicates={false}
                  dataSourceConfig={{ text: 'label', value: 'id' }}
                  fullWidth
                  helperText={'Add teams to receive your message'}
                  label="To:"
                  name="ChipInput"
                  onDelete={chip => handleDeleteChip(chip)}
                  required
                  rows="4"
                  value={chipData}
                  variant="outlined"
                />
              </Grid>

              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  className={classes.messageInput}
                  fullWidth
                  helperText={`${charCount}/${maxChars} characters available`}
                  inputProps={{
                    maxLength: maxChars
                  }}
                  label="Message:"
                  margin="dense"
                  multiline
                  name="message"
                  onChange={handleMessageChange}
                  required
                  rows="4"
                  value={messageValue}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions className={classes.cardActions}>
            <TeamDrawer
              addChip={addChip}
              chips={rightDrawerChips}
            />

            <Button
              color="primary"
              disabled={
                isLoading || charCount === maxChars
                  ? true
                  : chipData.length === 0
                    ? true
                    : false
              }
              endIcon={<SendIcon />}
              onClick={e => handleSubmit(e)}
              variant="contained"
            >
              Send
            </Button>
          </CardActions>
        </form>
      </Card>
    </div>
  );
};

SendForm.propTypes = {
  className: PropTypes.string
};

export default SendForm;
