import React, { useState, useEffect, useContext, Fragment } from 'react';
import { UserContext } from '../../../../UserContext';
import jwt_decode from 'jwt-decode';
import AddUserForm from './AddUserForm';
import EditUserForm from './EditUserForm';
import UserTable from './UserTable';
import { makeStyles } from '@material-ui/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import useFetch from '../../../../common/useFetch';
import fetchData from '../../../../common/fetchData';

const useStyles = makeStyles(() => ({
  userTable: {
    paddingTop: '2rem'
  }
}));

function Alert(props) {
  return <MuiAlert
    elevation={6}
    variant="filled"
    {...props}
  />;
}

const UserManager = () => {
  const classes = useStyles();

  const initialFormState = {
    id: null,
    first_name: '',
    last_name: '',
    mobile_phone: '',
    email: '',
    password: '',
    role: '',
    coach: ''
  };

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(initialFormState);
  const [editing, setEditing] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
    setOpenErrorSnack(false);
  };

  const { user, setUser } = useContext(UserContext);
  const { userId, customerId, leagueId } = jwt_decode(user.accessToken);

  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
      Authorization: `Bearer ${user.accessToken}`
    },
    credentials: 'include',
    body: JSON.stringify({
      customerId: customerId
    })
  };

  const { data } = useFetch('/user/list', config);

  useEffect(() => {
    if (data) {
      setUsers(data.users);
    }
  }, [data]);

  const addUser = async nUser => {
    //e.preventDefault();

    setIsLoading(true);

    try {
      const { data, newUser } = await fetchData(
        '/user',
        user.accessToken,
        'POST',
        {
          first_name: nUser.first_name,
          last_name: nUser.last_name,
          mobile_phone: nUser.mobile_phone,
          email: nUser.email,
          password: nUser.password,
          role: nUser.role,
          coach: nUser.coach,
          fk_league_id: leagueId,
          fk_customer_id: customerId
        }
      );

      if (data) {
        setUsers([...users, nUser]);
      }
      setIsLoading(false);
      setOpenSnack(true);

      if (newUser) {
        setUser(newUser);
      }
    } catch (e) {
      setIsLoading(false);
      setOpenErrorSnack(true);
      return e;
    }
  };

  const updateUser = async (id, uUser) => {
    setEditing(false);

    setIsLoading(true);

    console.log(`pre uUser: ${JSON.stringify(uUser)}`);

    try {
      const { data, newUser } = await fetchData(
        '/user',
        user.accessToken,
        'PUT',
        {
          userId: uUser.values.id,
          first_name: uUser.values.first_name,
          last_name: uUser.values.last_name,
          mobile_phone: uUser.values.mobile_phone,
          email: uUser.values.email,
          password: uUser.values.password,
          role: uUser.values.role,
          coach: uUser.values.coach,
          fk_league_id: leagueId,
          fk_customer_id: customerId
        }
      );

      console.log(`post data: ${JSON.stringify(data)}`);

      if (data) {
        setUsers(users.map(user => (user.id === id ? uUser.values : user)));
      }
      setIsLoading(false);
      setOpenSnack(true);

      if (newUser) {
        setUser(newUser);
      }
    } catch (e) {
      setIsLoading(false);
      setOpenErrorSnack(true);
      return e;
    }
  };

  const deleteUser = async id => {
    setEditing(false);

    setIsLoading(true);

    try {
      const { data, newUser } = await fetchData(
        '/user',
        user.accessToken,
        'DELETE',
        {
          userId: id
        }
      );

      if (data) {
        setUsers(users.filter(user => user.id !== id));
      }
      setIsLoading(false);
      setOpenSnack(true);

      if (newUser) {
        setUser(newUser);
      }
    } catch (e) {
      setIsLoading(false);
      setOpenErrorSnack(true);
      return e;
    }
  };

  const editRow = user => {
    setEditing(true);

    setCurrentUser({
      isValid: true,
      values: {
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
        mobile_phone: user.mobile_phone,
        email: user.email,
        password: user.password,
        role: user.role,
        coach: user.coach
      },
      touched: {},
      errors: {},
      isLoading: false
    });
  };

  return (
    <div className="container">
      <Snackbar
        autoHideDuration={6000}
        onClose={handleClose}
        open={openSnack}
      >
        <Alert
          onClose={handleClose}
          severity="success"
        >
          User updated successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={6000}
        onClose={handleClose}
        open={openErrorSnack}
      >
        <Alert
          onClose={handleClose}
          severity="error"
        >
          User update failed. Please try again.
        </Alert>
      </Snackbar>
      <div className="flex-row">
        <div className="flex-large">
          {editing ? (
            <Fragment>
              <EditUserForm
                currentUser={currentUser}
                editing={editing}
                setEditing={setEditing}
                updateUser={updateUser}
              />
            </Fragment>
          ) : (
            <Fragment>
              <AddUserForm
                addUser={addUser}
                loading={isLoading}
              />
            </Fragment>
          )}
        </div>
        <div className={classes.userTable}>
          <UserTable
            currentUserId={userId}
            deleteUser={deleteUser}
            editRow={editRow}
            users={users}
          />
        </div>
      </div>
    </div>
  );
};

export default UserManager;
