import { useEffect, useState, useContext } from 'react';
import { UserContext } from '../UserContext';

export const useFetch = (url, options) => {
  const { setUser } = useContext(UserContext);
  const [state, setState] = useState({
    data: null,
    loading: true,
    error: null
  });

  useEffect(() => {
    const API_URL =
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:4000'
        : 'https://api.teamupdate.me';
    const fetchData = async () => {
      try {
        const res = await fetch(API_URL + url, options);
        const json = await res.json();

        if (json.message === 'Access token expired') {
          const resNewAccessToken = await fetch(API_URL + '/auth/refresh', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Cache: 'no-cache'
            },
            credentials: 'include'
          });
          const newAccessToken = await resNewAccessToken.json();

          if (newAccessToken.isAuthenticated === true) {
            setUser({
              username: newAccessToken.username,
              isAuthenticated: newAccessToken.isAuthenticated,
              customerStatus: newAccessToken.customerStatus,
              accessToken: newAccessToken.accessToken,
              tokenExpiresOn: newAccessToken.tokenExpiresOn
            });

            options.headers.Authorization =
              'Bearer ' + newAccessToken.accessToken;

            const resRefreshed = await fetch(API_URL + url, options);
            const jsonRefreshed = await resRefreshed.json();

            setState({ data: jsonRefreshed, loading: false, error: null });
          } else {
            setState({ data: json, loading: false, error: null });
          }
        } else {
          setState({ data: json, loading: false, error: null });
        }
      } catch (err) {
        setState({ data: null, loading: false, error: err });
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [url, setUser]);
  return state;
};

export default useFetch;
